import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import MenuMore from "components/MenuMore";
import { getMiniPlayerSelector, getCollapsedSelector } from "modules/MiniPlayer/selectors";
import { dispatchUpdateModalWebPlayer } from "modules/MiniPlayer/actions";
import { dispatchUpdateMiniPlayer } from "modules/MiniPlayer/actions";
import { getLangSelector, getLoginSelector } from "modules/Config/selectors";
import {
  getTitleFromTrack,
  getWorkFromTrack,
  getClassicalImageFromTrack,
  getTrackIdFromTrack,
  getRecordYear,
  getJazzAlbumImageFromTrack,
  getComposerFromTrack,
  getUniverseFromTrack,
  getArtistsFromTrack,
  getAlbumFromTrack,
  getDisplayArtistFromTrack,
  isCountryAllowed,
} from "utils/metaDataTools";
import {
  classicalAlbumOption,
  composerOption,
  workOption,
  jazzAlbumOption,
  artistOption,
  recordingOption,
  podcastOption,
  chapterOption,
} from "components/MenuMore/MenuMoreOptions.js";
import iconPlayCircleRed from "assets/images/icon_play_circle_red.svg";
import iconPlayCircleBlue from "assets/images/icon_play_circle_blue.svg";
import iconPlaying from "assets/images/icon_playing.svg";
import jazzIconPlaying from "assets/images/jazz_icon_playing.svg";
import getAbsolutePath from "utils/getAbsolutePath";
import iconPlayDisabled from "assets/images/play-icon-disabled.svg";
import EnhancedImage from "components/EnhancedImage";
import { getOverlayImg } from "utils/storeUtils";
import defaultArtistImage from "assets/images/default_person_avatar.svg";
import { isAllowedToPlay } from "utils/checkHasAccess";
import { PAGE_ORIGIN_PODCASTS } from "utils/nameVariables";

const ExpandedPlayerQueue = ({ t, isLogged, lang, miniPlayer, updateMiniPlayer, collapsePlayer, isMobile, updateShowModalWebPlayer }) => {
  const { dataTrack, indexOfTrack, playing, jazzMode } = miniPlayer;
  const isPodcastStream = miniPlayer?.pageOrigin === PAGE_ORIGIN_PODCASTS;
  const podcastId = dataTrack && dataTrack[indexOfTrack].podcast_id;
  const chapterId = dataTrack && dataTrack[indexOfTrack].id;

  const OPERA_GROUP = "OperaDeParis";
  const AUDIORIUM = "ADLyon";
  const CHAMPSELYSEES = "Champs-elysees";

  const handlePlaySong = (newIndex) => {
    const { dataTrack } = miniPlayer;
    const url = new URL(window.location.href);
    const utmSource = url.searchParams.get("utm_source");

    if (isPodcastStream || isLogged || (utmSource && (utmSource === OPERA_GROUP || utmSource === AUDIORIUM || utmSource === CHAMPSELYSEES))) {
      if (isPodcastStream || isAllowedToPlay(dataTrack[newIndex])) {
        updateMiniPlayer({
          playing: true,
          indexOfTrack: newIndex,
          playlist: null,
          playlistId: null,
        });
      }
    } else {
      updateShowModalWebPlayer(true);
    }
  };
  return (
    <ul className="list-of-queue mobile">
      {getOverlayImg(true)}
      <p className="title font-24 playfair px-20 py-10">{t("PLAYER_QUEUE")}</p>
      {dataTrack.map((item, index) => {
        const universe = getUniverseFromTrack(item);
        const isJazz = universe === "jazz";
        const trackComposer = getComposerFromTrack(item);
        const trackArtists = getArtistsFromTrack(item);
        const trackComposerId = trackComposer ? trackComposer.id : "";
        const trackName = isPodcastStream ? item.name : isJazz ? trackArtists.join(", ") : trackComposer ? trackComposer.name : "";
        const trackId = getTrackIdFromTrack(item);
        const trackTitle = isPodcastStream ? item?.podcast?.name || item.podcast_name : getTitleFromTrack(item);
        const { imageRootUrl: jazzTrackAlbumRootUrl, imagePath: jazzTrackAlbumPath } = getJazzAlbumImageFromTrack(item);
        const { imageRootUrl: classicalTrackAlbumRootUrl, imagePath: classicalTrackAlbumPath } = getClassicalImageFromTrack(item);
        const trackImage = isPodcastStream ? (
          <img src={item.image_url || defaultArtistImage} size="small" alt={trackName} />
        ) : isJazz ? (
          <EnhancedImage
            size="small"
            imageRootUrl={jazzTrackAlbumRootUrl}
            imagePath={jazzTrackAlbumPath}
            alt={trackName}
            fallbackImage={defaultArtistImage}
          />
        ) : (
          <EnhancedImage
            size="small"
            imageRootUrl={classicalTrackAlbumRootUrl}
            imagePath={classicalTrackAlbumPath}
            alt={trackName}
            fallbackImage={defaultArtistImage}
          />
        );

        const album = getAlbumFromTrack(item);
        const work = getWorkFromTrack(item);
        const recYear = getRecordYear(item);
        const displayArtist = getDisplayArtistFromTrack(item);
        const trackDisabled = !isCountryAllowed(item);
        return (
          <li
            key={`${trackComposerId}_${index}`}
            className={`${trackDisabled ? "track-disabled" : ""} ${
              index === indexOfTrack ? `current-track${isJazz ? " jazz" : ""}` : `${isJazz ? "jazz" : ""}`
            }`}
          >
            <div className="list-of-queue__composer">
              {index === indexOfTrack && playing && (
                <div className="icon-playing">
                  <img src={getAbsolutePath(isJazz ? jazzIconPlaying : iconPlaying)} alt="" />
                </div>
              )}
              {(index !== indexOfTrack || !playing) && (
                <div className="image-composer">
                  {trackImage}
                  <img
                    onClick={() => handlePlaySong(index)}
                    className="icon-play-circle-red"
                    src={getAbsolutePath(trackDisabled ? iconPlayDisabled : isJazz ? iconPlayCircleBlue : iconPlayCircleRed)}
                    alt=""
                  />
                </div>
              )}
            </div>
            <div className="list-of-queue__info-track" onClick={() => handlePlaySong(index)}>
              {trackName && <div className="composer-text">{trackName}</div>}
              <div className="track-title">{trackTitle}</div>
            </div>
            {isPodcastStream ? (
              <MenuMore
                hideAddToPlaylist={true}
                runAfterRedirect={collapsePlayer}
                urlOptions={[podcastOption(podcastId, isJazz, lang, t), chapterOption(chapterId, isJazz, lang, t)]}
              />
            ) : (
              isLogged &&
              !isMobile && (
                <MenuMore
                  isJazz={isJazz}
                  runAfterRedirect={collapsePlayer}
                  listSongs={[trackId]}
                  track={item}
                  urlOptions={
                    jazzMode
                      ? album && [jazzAlbumOption(album, lang, t), artistOption(displayArtist, lang, t)]
                      : [
                          composerOption(displayArtist, lang, t),
                          classicalAlbumOption(album, lang, t),
                          workOption(work, lang, t),
                          recordingOption(displayArtist, work, recYear, lang, t),
                        ]
                  }
                />
              )
            )}
          </li>
        );
      })}
    </ul>
  );
};

const mapStateToProps = (state) => ({
  miniPlayer: getMiniPlayerSelector(state),
  lang: getLangSelector(state),
  collapsed: getCollapsedSelector(state),
  isLogged: getLoginSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  updateMiniPlayer: (data) => {
    dispatch(dispatchUpdateMiniPlayer(data));
  },
  updateShowModalWebPlayer: (showModal) => {
    dispatch(dispatchUpdateModalWebPlayer(showModal));
  },
});

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(ExpandedPlayerQueue)));
