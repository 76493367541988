import React, { Component, lazy, Suspense } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { getIsWebPlayerSelector } from "modules/MiniPlayer/selectors";
import { dispatchUpdateMiniPlayer } from "modules/MiniPlayer/actions";
import {
  getCheckSubscriptionUpdateSelector,
  getOverflowSelector,
  getLoginSelector,
  getLangSelector,
  getShowFreeTrialSelector,
  getIsJazzSelector,
} from "modules/Config/selectors";
import { dispatchUpdateAccount } from "modules/Account/actions";
import { dispatchSetPlaylists } from "modules/Library/actions";
import { dispatchUpdateIsFlacEnabled, dispatchLogin, dispatchCheckSubscriptionUpdate } from "modules/Config/actions";
import ErrorBoundary from "..//ErrorBoundary";
import ApplicationRoutes from "components/ApplicationRoutes";
import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { isIE } from "react-device-detect";
import NoIE from "pages/NoIE";
import { getFlacAllowed } from "utils/commonApis";
import { getUserCountrySelector } from "modules/Account/selectors";
import prerenderMarker from "utils/prerenderMarker";
import { getRedirectRoute, setFreeTvSessionParam } from "utils/tools";
import { apiLibraryGetClient, apiLibraryRefreshClientCache } from "utils/apiLibrary/client";
import isEmpty from "lodash/isEmpty";
import { CLASSICAL_NAME, JAZZ_NAME, PAGE_ORIGIN_UNKNOWN } from "utils/nameVariables";
import { trackFBPageView, initFBPixel } from "utils/FacebookManager";
import { Mixpanel } from "utils/mixpanel";
import partnerDetails from "pages/FormRegisterPartners/partnerDetails.json";
import SideWidget from "components/SideWidget";

import ExpandedPlayer from "pages/ExpandedPlayer";
import MobileMenu from "components/MobileMenu";
import Footer from "components/Footer";
import SmallLoader from "components/SmallLoader";
import { apiLibraryGetWelcomeBanner } from "utils/apiLibrary/homepage";
import {
  apiLibraryGetFavouritePersons,
  apiLibraryGetFavouriteVialmaPlaylists,
  apiLibraryGetUserLikedAlbums,
  apiLibraryGetUserLikedCollections,
  apiLibraryGetUserLikedTracks,
  apiLibraryGetUserLikedVideos,
  apiLibraryGetUserPlaylists,
} from "utils/apiLibrary/library";
import { pendoInitHandler } from "utils/pendo";

const FreeTrialOver = lazy(() => import("components/FreeTrialOver"));
const LandingPageRadio = lazy(() => import("pages/LandingPageRadio"));
const FormRegisterPartners = lazy(() => import("pages/FormRegisterPartners"));
const ScrollTop = lazy(() => import("../ScrollTop"));

class ContainerApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkSubscriptionUpdateCounter: 0,
      checkSubscriptionUpdateIntervalId: null,
    };
  }

  async componentDidMount() {
    prerenderMarker();
    pendoInitHandler()
    window.$crisp?.push?.(["on", "chat:opened", this.updateCrispDimensions]);
    setFreeTvSessionParam();
    const { isLogged, lang, setAccount, updateMiniPlayer, isJazzSelected } = this.props;
    const miniPlayerData = JSON.parse(localStorage.getItem("miniplayer"));
    initFBPixel();
    document.documentElement.lang = lang;
    if (miniPlayerData && isLogged) {
      updateMiniPlayer({...miniPlayerData, playing: false});
    }

    await this.tryLoginViaToken();
    const loginData = JSON.parse(localStorage.getItem("loginData"));
    if (isLogged) {
      if (!loginData) {
        this.logOut();
        return;
      }
      let responseAccount;
      try {
        responseAccount = await apiLibraryGetClient();
        setAccount(responseAccount);
        this.updateLocalStorage(responseAccount); // temporary measure until everyone's localstorage is updated.
      } catch (error) {
        if (`${process.env.REACT_APP_DOMAIN}`.includes("www.vialma.com") || `${process.env.REACT_APP_DOMAIN}`.includes("pre-prod.vialma.com")) {
          // Temporary solution for bad bearer token until we figure out what's wrong.
          localStorage.clear();
          return;
        }
      }

      window.dataLayer.push({
        event: "pageview",
        userId: responseAccount?.subscription_ensemble?.user?.id,
        page: {
          location: window.location.href,
          path: window.location.pathname,
          universe: isJazzSelected ? JAZZ_NAME : CLASSICAL_NAME,
        },
      });
      this.getFlacEnabled();
      Mixpanel.track("Pageview", { universe: isJazzSelected ? JAZZ_NAME : CLASSICAL_NAME });

      this.loginBackgroundEvents();
      if (getRedirectRoute()) {
        window.location = getRedirectRoute();
      }
    } else {
      window.dataLayer.push({
        event: "pageview",
        page: {
          location: window.location.href,
          path: window.location.pathname,
        },
      });
    }
    trackFBPageView();
  }
  loginBackgroundEvents = () => {
    apiLibraryGetWelcomeBanner();
    apiLibraryGetUserPlaylists();
    apiLibraryGetUserLikedTracks();
    apiLibraryGetFavouritePersons();
    apiLibraryGetUserLikedAlbums(-1);
    apiLibraryGetUserLikedVideos(-1);
    apiLibraryGetFavouriteVialmaPlaylists();
    apiLibraryGetUserLikedCollections(-1);
  };

  updateLocalStorage = (responseAccount) => {
    const loginData = JSON.parse(localStorage.getItem("loginData"));
    const updatedLogin = { ...loginData, ...responseAccount };
    localStorage.setItem("loginData", JSON.stringify(updatedLogin));
  };

  logOut = () => {
    const { updateMiniPlayer, setAccount, setLogin } = this.props;
    Mixpanel.reset();

    updateMiniPlayer({
      playing: false,
      url: null,
      indexOfTrack: 0,
      dataTrack: null,
      isWebPlayer: false,
      collapsed: true,
      slug: null,
      composerImage: null,
      composerName: null,
      composerSlug: null,
      composerId: null,
      playlist: null,
      playlistId: null,
      pageOrigin: PAGE_ORIGIN_UNKNOWN,
      mediaId: null,
      mediaProps: null,
    });

    setAccount(null);
    setLogin(false);
  };

  tryLoginViaToken = async () => {
    const { setAccount, setLogin } = this.props;

    const url = new URL(window.location.href);
    const tokenHash = url.searchParams.get("token");

    const baToken = url.searchParams.get("ba_token"); //should be unique to paypal redirect after payments
    if (baToken) return;

    if (!tokenHash) return; // don't try to login if no token is available in query parameter
    try {
      const responseAccount = await apiLibraryGetClient();
      this.logOut();
      localStorage.setItem("loginData", JSON.stringify(Object.assign({}, { token: tokenHash, name: "" }, responseAccount)));
      setAccount(responseAccount);
      setLogin(true);
    } catch (error) {}
  };

  getFlacEnabled = async () => {
    const { setFlacIsAllowed } = this.props;
    const result = await getFlacAllowed();
    setFlacIsAllowed(result);
  };

  checkSubscriptionUpdateFunction = async () => {
    const { checkSubscriptionUpdateCounter, checkSubscriptionUpdateIntervalId } = this.state;
    const { setCheckSubscriptionUpdate, setAccount } = this.props;
    try {
      await apiLibraryRefreshClientCache();
      const responseAccount = await apiLibraryGetClient();

      //subscription is said to be updated if
      //when expired false -> true
      //when subscription universes empty array -> not empty e.g. ["Classical", "Jazz"]
      const hasSubscriptionUpdated =
        !isEmpty(responseAccount?.subscription_ensemble?.subscription?.universes) && responseAccount?.subscription_ensemble?.subscription?.expired;

      //if 5 minute limit reached, set account to response and clear regardless of if theres a subscription update or not
      if (checkSubscriptionUpdateCounter > 4) {
        setAccount(responseAccount);
        clearInterval(checkSubscriptionUpdateIntervalId);
        setCheckSubscriptionUpdate(false);
      }
      // if theres a subscription update, set account to updated information,
      // else increase the counter
      if (hasSubscriptionUpdated) {
        this.setState({
          checkSubscriptionUpdateCounter: checkSubscriptionUpdateCounter + 5,
        });
        clearInterval(checkSubscriptionUpdateIntervalId);
        setAccount(responseAccount);
        setCheckSubscriptionUpdate(false);
      } else {
        this.setState({
          checkSubscriptionUpdateCounter: checkSubscriptionUpdateCounter + 1,
        });
      }
    } catch (e) {
      this.setState({
        checkSubscriptionUpdateCounter: 5,
      });
      clearInterval(checkSubscriptionUpdateIntervalId);
    }
  };

  componentDidUpdate(prevProps) {
    const { lang, checkSubscriptionUpdate, isLogged } = this.props;
    if (prevProps.lang !== lang) {
      document.documentElement.lang = lang;
    }
    // Workaround for new subscription because of possible delays in updating the subscription universes or expired state
    // Will rerun the client call for up to 5 mins to check for the update
    const { checkSubscriptionUpdateCounter } = this.state;

    if (checkSubscriptionUpdate && prevProps.checkSubscriptionUpdate !== checkSubscriptionUpdate && checkSubscriptionUpdateCounter < 5) {
      const checkSubscriptionUpdateInterval = setInterval(this.checkSubscriptionUpdateFunction, 10000);
      this.setState({
        checkSubscriptionUpdateIntervalId: checkSubscriptionUpdateInterval,
      });
    }

    if (isLogged !== prevProps.isLogged && isLogged) {
      this.loginBackgroundEvents();
    }
  }

  componentWillUnmount() {
    window.$crisp?.push?.(["off", "chat:opened"]);
  }

  updateCrispDimensions = () => {
    // to adjust the height of the chat window text container
    const chatWindow = document.getElementsByClassName("cc-qqzz")?.[0];
    const windowWidth = window.matchMedia("(min-width: 768px)");
    if (chatWindow && windowWidth.matches) {
      //80 offset in crisp and 100 height of top section of crisp chat, totalling 180
      const windowHeight = window.innerHeight - 180;
      chatWindow.style.setProperty("height", `${windowHeight * 0.7}px`, "important");
    }
  };

  render() {
    const { isWebPlayer, overflow, isLogged, showFreeTrial, t } = this.props;
    const showSideWidget = t("SIDE_WIDGET_URL") !== "SIDE_WIDGET_URL" && !isLogged;
    if (isIE) return <NoIE />;
    return (
      <Router>
        <ErrorBoundary>
          <ScrollTop>
            <ReactNotifications />
            <div className={isWebPlayer ? `container-app is-webplayer ${overflow}` : `container-app ${overflow}`}>
              <div className="container-app">
                <Suspense fallback={<SmallLoader isPage />}>
                  <Switch>
                    <Route exact path="/radioclassique" component={LandingPageRadio} />
                    <Route exact path="/radioclassique/:composer" component={LandingPageRadio} />

                    {partnerDetails.map((partner, index) => (
                      <Route key={index} exact path={partner.path} render={() => <FormRegisterPartners {...partner.props} />} />
                    ))}
                    <Route path="/:lng(en|fr)?" component={ApplicationRoutes} />
                  </Switch>
                </Suspense>
              </div>

              {Boolean(window.top === window.self) && <div className="bottom-spacer" />}
              <div id="playerContainer" className="bottom-fixed">
                {isWebPlayer && <ExpandedPlayer />}
                {Boolean(window.top === window.self && isLogged) && <MobileMenu />}
              </div>
              {isLogged && showFreeTrial && <FreeTrialOver />}
              <Footer />
            </div>
          </ScrollTop>
          {showSideWidget && <SideWidget url={t("SIDE_WIDGET_URL")} text={t("SIDE_WIDGET_COPY")} />}
        </ErrorBoundary>
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
  isWebPlayer: getIsWebPlayerSelector(state),
  overflow: getOverflowSelector(state),
  isLogged: getLoginSelector(state),
  lang: getLangSelector(state),
  showFreeTrial: getShowFreeTrialSelector(state),
  userCountry: getUserCountrySelector(state),
  checkSubscriptionUpdate: getCheckSubscriptionUpdateSelector(state),
  isJazzSelected: getIsJazzSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  updateMiniPlayer: (data) => {
    dispatch(dispatchUpdateMiniPlayer(data));
  },
  setAccount: (account) => {
    dispatch(dispatchUpdateAccount(account));
  },
  setPlaylists: (playlists) => {
    dispatch(dispatchSetPlaylists(playlists));
  },
  setFlacIsAllowed: (isEnabled) => {
    dispatch(dispatchUpdateIsFlacEnabled(isEnabled));
  },
  setLogin: (data) => {
    dispatch(dispatchLogin(data));
  },
  setCheckSubscriptionUpdate: (checkSubscriptionUpdate) => {
    dispatch(dispatchCheckSubscriptionUpdate(checkSubscriptionUpdate));
  },
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ContainerApp));
